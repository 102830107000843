import React, { useState, useEffect, useRef } from "react";
import NET from  "vanta/dist/vanta.net.min";
import * as THREE from "three";
import Typer from "./name";

export const MyComponent = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const vantaRef = useRef(null);

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        NET({
          el: vantaRef.current,
            THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.00,
            minWidth: 200.00,
            scale: 1.00,
            scaleMobile: 1.00,
            color: 0x2b889a,
            backgroundColor: 0xb133d,
            points: 20.00,
            maxDistance: 25.00,
            spacing: 20.00
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);
  return (
    <div ref={vantaRef}>
        <p className="sfondoVantaJs" style={{ color: "#FFF6E6", paddingTop: "20px", height: "92vh" }}>
        <Typer className="nomi"  
          dataText={[  
            'Welcome!',
            'Full-Stack Web Developer',
            'BI Data Analyst',
            'Data Engeneer',
            '  F|B  '
      ]}
      />
      </p>
    </div>
  );
}
