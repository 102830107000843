import React, {useEffect } from 'react';
import Navbar from './components/pages/Navbar/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import BlogHome from './components/pages/BlogHome';
import ContactUs from './components/pages/ContactUs';
import AboutMe from './components/pages/AboutMe.js';
import { AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-0SBX9XNTPZ";

ReactGA.initialize(TRACKING_ID);

const App = () => {

    useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AnimatePresence>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/about-me' component={AboutMe} />
          <Route path='/homeBlog' component={BlogHome} />
          <Route exact path='/contact-us' component={ContactUs} />
        </Switch>
      </Router>
    </AnimatePresence>
  );
}

export default App;
