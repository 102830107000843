import React from 'react';
import emailjs from "emailjs-com";
/* import {toast} from "react-toastify"; */
/* import 'react-toastify/dist/ReactToastify.css'; */
import AnimatedPage from './AnimatedPage';
import { GiPositionMarker } from 'react-icons/gi';
import './Contact/contact.scss';
import './Contact/contactus.css'
/* import 'react-bootstrap'
import { ToastContainer, toast } from 'react-toastify'; */
 /*  import 'react-toastify/dist/ReactToastify.css'; */

/* toast.configure(); */


export default class Contact extends React.Component { 

  constructor(props) {
    super(props);
    this.state = {
      nome:'',
      email:'',
      testo: '',
      oggetto:'',
      trattamentoDati: false
    };
    this.handleInputChange = this.handleInputChange.bind(
      this
    );
  }
    
  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  inviaEmail(e) {
    // preventDefault annulla l'evento di default
    e.preventDefault();
    emailjs.sendForm('service_coding_ds', 'template_gx8a79t', e.target, 'user_Jf0TOh2qnIviPJR7jtKci')
            .then((result) => {
              /* toast.success("Email inviata con successo"); */
              console.log(result.text);
            }, (error) => {
              /* toast.error("Invio Email non riuscito"); */
              console.log(error.text);
            });
    // reset degli input del form contattaci
    e.target.reset();
  }

  render() {  
    return (
      <AnimatedPage>
      <h1 className='titleMail'>&#60;Contact &#47;&#62;</h1>
      
      <div className='contenitore_mail'>
  
        <form onSubmit={this.inviaEmail} className='contatto'>
          <input name="nome" type="text" className='nome' required placeholder='Name'
            checked={this.state.nome}
            onChange={this.handleInputChange} />                                             
          <input name="email" type="text" className='mail' required placeholder='Mail'
            checked={this.state.email}
            onChange={this.handleInputChange} />                         
          <input name="oggetto" type="text" className=' oggetto ' required placeholder='Object'
            checked={this.state.oggetto}
            onChange={this.handleInputChange} />                       
          <br />
          <textarea name="testo" checked={this.state.testo} onChange={this.handleInputChange} className='form-control testo' required placeholder='Message'  />                      
          <br />
          <div className='modulo_trattamento_dato'>
              
          <span className='acconsento'>Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs 196 del 30 giugno 2003 e dell'art. 13 GDPR </span>
          <input name="trattamentoDati" className='trattamentoDati' type="checkbox" required
            checked={this.state.trattamentoDati}
            onChange={this.handleInputChange} />                                
            
            </div>  
          <input type="submit" className="btn_mail" value="SEND"></input>                   
          </form>

        <div className="typewriter">
          <div className="slide"><i></i></div>
          <div className="paper"></div>
          <div className="keyboard"></div>
        </div>
        <div className='divider'></div>
        </div>
        </AnimatedPage>
      );
    }
  }
 
