import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import './Blog/blog.css';
import Blog from './Blog/pages/Blog/index.jsx';
import HomeBlog from './Blog/pages/Home/index.jsx';
import AnimatedPage from './AnimatedPage';



const BlogHome = () => {
  return (
    <AnimatedPage>
    <div className='container_blog'>
      <Switch>
      <Route path='/homeBlog' exact component={HomeBlog} />
      <Route path='/homeBlog/blog/:id' component={Blog} />
      <Redirect to='/' />
      </Switch>
      </div>
      </AnimatedPage>
  );
};

export default BlogHome;
