import { articolo1 } from "./art1";
import { articolo2 } from "./art2";
import { articolo3 } from "./art3";
import { articolo4 } from "./art4";
import { articolo5 } from "./art5";
import { articolo6 } from "./art6";

export const blogList = [
  {
    id: 0,
    title: 'Presentazione Sito',
    category: 'Presentazione',
    subCategory: ['Data Analyst', 'Data Science'],
    description:  articolo1,
    authorName: 'Fabrizio Brancato',
    authorAvatar: '/assets/images/me.png',
    createdAt: 'May 20, 2023',
    cover: '/assets/images/Ciao.png',
  },
/*   {
    id: 1,
    title: "Cos'è la Data Science? ",
    category: 'Data Science',
    subCategory: ['Data Analyst', 'Data Science', 'Data Engeneer', 'IA'],
    description:  articolo2,
    authorName: 'Fabrizio Brancato',
    authorAvatar: '/assets/images/me.png',
    createdAt: 'June 20, 2023',
    cover: '/assets/images/art2/data_science_image.jpg',
  },

  {
    id: 2,
    title: 'Data Mind | YouTube Channel',
    category: 'YouTube',
    subCategory: ['Data Analyst', 'Data Science', 'Youtube'],
    description:  articolo3,
    authorName: 'Fabrizio Brancato',
    authorAvatar: '/assets/images/me.png',
    createdAt: 'June 20, 2023',
    cover: '/assets/images/art2/dm.png',
  },
  {
    id: 3,
    title: 'Flow Chart | Road Map',
    category: 'Formazione',
    subCategory: ['Data Analyst', 'Data Science', 'Data Engeneer'],
    description:  articolo4,
    authorName: 'Fabrizio Brancato',
    authorAvatar: '/assets/images/me.png',
    createdAt: 'June 21, 2023',
    cover: '/assets/images/art3/banner_stats.png',
  },
  {
    id: 4,
    title: 'Google Data Analytics Professional Certificate',
    category: 'Formazione',
    subCategory: ['Data Analyst', 'Data Science', 'Data Engeneer'],
    description:  articolo5,
    authorName: 'Fabrizio Brancato',
    authorAvatar: '/assets/images/me.png',
    createdAt: 'June 21, 2023',
    cover: '/assets/images/art5/google_data_analyst.jpg',
  },
  {
    id: 5,
    title: 'IBM Data Science Professional Certificate',
    category: 'Formazione',
    subCategory: ['Data Analyst', 'Data Science', 'Data Engeneer'],
    description:  articolo6,
    authorName: 'Fabrizio Brancato',
    authorAvatar: '/assets/images/me.png',
    createdAt: 'June 21, 2023',
    cover: '/assets/images/art6/ibm_data_science.png',
  } */
];
