import React from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2'
ChartJS.register(...registerables);

const BarChart = () => {
    return <div id='Barchart'>
        <Bar
            data={{
                labels: ['I Trim', 'II Trim', 'III Trim', 'IV Trim'],
                datasets: [
                    {
                        type: 'line',
                        label: 'Guadagni',
                        fill:true,
                        data: [7000, 5000, 9000, 15000],
                        backgroundColor: ['rgb(255,0, 0, 0.3)'],
                        borderColor: ['rgb(255, 99, 132)']
                    },
                       {
                        type: 'line',
                        label: 'Costi',
                        data: [20500, 5000, 24000, 35000],
                        backgroundColor: ['rgb(255, 99, 1)']
                    },
                    {
                        type: 'bar',
                        label: 'Ricavo Effettivo',
                        data: [27500, 10000, 33000, 50000],
                        backgroundColor: ['rgb(75, 192, 192)']
                    },
                    {
                        type: 'bar',
                        label: 'Ricavo Atteso',
                        data: [29000, 17000, 31500, 45000],
                        backgroundColor: ['rgb(53, 162, 235)']
                    },
                 
                ]
            }}
            options={{
                maintainAspectRatio:false,
            }}
            className="grafico"
        />

    
     
    </div>

}

export default BarChart;