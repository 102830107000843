import { motion } from "framer-motion";
import { ReactDOM, useState } from "react";
import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Github, YouTube, Linkedin, Kaggle, Streamlit } from "../Home/allsvg";

const Icons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  right: 2rem;
  z-index: 2;
  width: max-content;

  nth-child(1) {
    fill: red;
  }
  nth-child(1):hover {
    fill: red;
  }

  & > *:not(:last-child) {
    margin: 1rem;
  }
`;

const Line = styled(motion.span)`
  width: 2px;
  height: 6rem;
  background-color: #fff6e6;
`;

const lightTheme = {
  body: "#FFF6E6",
  text: "#FFF6E6",
  fontFamily: "'Source Sans Pro', sans-serif",
  bodyRgba: "252, 246, 244",
  textRgba: "0,0,0",
};

const SocialIcons = (props) => {
  return (
    <Icons className="icons">
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1 }}
      >
        <NavLink
          style={{ color: "inherit" }}
          target="_blank"
          to={{ pathname: "https://discuss.streamlit.io/u/fabrizio.brancato/summary" }}
        >
          <Streamlit className="streamlit" width={35} height={35} />
        </NavLink>
      </motion.div>

      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1 }}
      >
        <NavLink
          style={{ color: "inherit" }}
          target="_blank"
          to={{ pathname: "https://github.com/TheDrake94" }}
        >
          <Github className="github" width={35} height={35} />
        </NavLink>
      </motion.div>

      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1 }}
      >
        <NavLink
          style={{ color: "inherit" }}
          target="_blank"
          to={{ pathname: "https://www.kaggle.com/fabriziobrancato" }}
        >
          <Kaggle className="kaggle" width={35} height={35} />
        </NavLink>
      </motion.div>
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.4 }}
      >
        <NavLink
          style={{ color: "inherit" }}
          target="_blank"
          to={{
            pathname:
              "https://www.linkedin.com/in/fabrizio-brancato-189637246/",
          }}
        >
          <Linkedin className="linkedin" width={35} height={35} />
        </NavLink>
      </motion.div>
      <motion.div
        initial={{ transform: "scale(0)" }}
        animate={{ scale: [0, 1, 1.5, 1] }}
        transition={{ type: "spring", duration: 1, delay: 1.6 }}
      >
        <NavLink
          style={{ color: "inherit" }}
          target="_blank"
          to={{
            pathname:
              "https://www.youtube.com/channel/UClyNINRCKJ1PPv31oN7oLnw",
          }}
        >
          <YouTube className="youtube" width={35} height={35} />
        </NavLink>
      </motion.div>

      <Line
        className="line"
        color={props.theme}
        initial={{
          height: 0,
        }}
        animate={{
          height: "15rem",
        }}
        transition={{
          type: "spring",
          duration: 1,
          delay: 0.8,
        }}
      />
    </Icons>
  );
};

export default SocialIcons;
