import React ,{useState, useEffect}from "react";
import Chart from 'react-apexcharts';


function Donutchart()
{
    const [contryname, setCountryname]= useState([]);
    const [medal, setMedal]= useState([]);

    useEffect( ()=>{
     const getdata= async()=>{
          const countryname=[];
          const getmedal=[];

        const reqData= await fetch("http://localhost/reactgraphtutorial/medals"); 
        const resData= await reqData.json();
        for(let i=0; i<resData.length; i++)
        {
            countryname.push(resData[i].country);
            getmedal.push(parseInt(resData[i].medals));
        }
        setCountryname(countryname);
        setMedal(getmedal);
     

     }
     getdata();
    },[]);

    return(
        <React.Fragment>
            <div className='donut'>        
          <Chart 
            type="donut"
            height={400}
            series= {[25, 50, 25]}
            
            options={{
            legend: {
            position:'bottom'    
            },    
            labels: ['Data science', 'Developer', 'Data Analyst'],
            plotOptions:{
            pie:{
                donut:{
                    labels:{
                        show:true,
                        total:{
                            show:true,
                            showAlways:true,
                            fontSize:20,
                            color: '#FF8066',
                        }
                    }
                }
             }

             },

             dataLabels:{
                enabled:true,
             }


            }}
            
            />

            </div>
        </React.Fragment>
    );
}
export default Donutchart;