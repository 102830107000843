import React from 'react';
import './Button.css';
import { Link } from 'react-router-dom';
import './Button.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeCircleCheck } from '@fortawesome/free-solid-svg-icons'


export function Button() {
  return (
    <Link exact to='/contact-us/'>
      
      <button className='btn contact_me'>Contact <FontAwesomeIcon className='mail' icon={faEnvelopeCircleCheck}/>
      
      </button>
      
    </Link>
  );
}
