import React, { useState } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import Dropdown from './Dropdown';
import SoundBar from './sound';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDragon, faHouseChimney , faBlog, faEnvelope, faChargingStation, faRss, faChartGantt, faAtom  } from '@fortawesome/free-solid-svg-icons'


function Navbar() {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseEnter2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

    const onMouseLeave2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(false);
    }
  };

  return (
    <>
      <nav className='navbar'>
        
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <span className='iniziale'>F</span>abrizio <span className='iniziale'>B</span>rancato <FontAwesomeIcon className='drake' icon = {faAtom}/>
        </Link>
        <div className='menu-icon' onClick={handleClick}>
          <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
        </div> 
        <ul className={click ? 'nav-menu active' : 'nav-menu'}> 
          <li className='nav-item'>
            <SoundBar className='nav-links sound'/>
          </li>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              <span className='iniziale'>H</span>ome <FontAwesomeIcon className='homeico' icon = {faHouseChimney}/>
            </Link>
          </li>
              <li className='nav-item'>
            <Link
              to='/about-me'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <span className='iniziale'>A</span>bout <span className='iniziale'>M</span>e <FontAwesomeIcon className='Me' icon = {faChartGantt}/>
            </Link>
          </li>
          <li
            className='nav-item'
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          >
            <Link
              to='/homeBlog'
              className='nav-links'
              onClick={closeMobileMenu}
            >
              <span className='iniziale'>B</span>log <FontAwesomeIcon className='mail' icon = {faRss}/>
            </Link>
          </li>
          <li>
            <Link
              to='/contact-us'
              className='nav-links-mobile'
              onClick={closeMobileMenu}
            >
            Contact
            </Link>
          </li>
        </ul>
        <Button />
      </nav>
    </>
  );
}

export default Navbar;
