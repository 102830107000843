import { whiten } from "@chakra-ui/theme-tools";

export const RadarData = {
  labels: ["Software Developer", "Data Engeenering", "Data Visualizzation", "BI Tools", "Deep/Machine Learning",'Statistics', 'Data Architect'],
  datasets: [
     {
      label: "2021",
      backgroundColor: "rgba(240, 230, 140, 0.3)",
      borderColor: "rgba(240, 230, 140)",
      pointBackgroundColor: "rgba(240, 230, 140)",
      poingBorderColor: "#FFF6E6",
      pointHoverBackgroundColor: "#FFF6E6",
      pointHoverBorderColor: "rgba(240, 230, 140)",
      data: [55, 20, 20, 35, 15,15,5]
      },
      {
      label: "2022",
      backgroundColor: "rgba(104, 104, 171, .3)",
      borderColor: "rgba(104, 104, 171, 1)",
      pointBackgroundColor: "rgba(104, 104, 171, 1)",
      poingBorderColor: "#FFF6E6",
      pointHoverBackgroundColor: "#FFF6E6",
      pointHoverBorderColor: "rgba(104, 104, 171, 1)",
      data: [70, 40, 60, 55, 35,30,20]
    },
      {
      label: "2023",
      backgroundColor: "rgba(187, 38, 73, .3)",
      borderColor: "rgba(187, 38, 73, 1)",
      pointBackgroundColor: "rgba(187, 38, 73, 1)",
      poingBorderColor: "#FFF6E6",
      pointHoverBackgroundColor: "#FFF6E6",
      pointHoverBorderColor: "rgba(187, 38, 73, 1)",
      data: [80, 45, 65, 65, 45,45,30]
    }
    ,
      {
      label: "2024",
      backgroundColor: "rgba(255,199,185,1.00)",
      borderColor: "rgba(255,75,30,1.00)",
      pointBackgroundColor: "rgba(187, 38, 73, 1)",
      poingBorderColor: "#FFF6E6",
      pointHoverBackgroundColor: "#FFF6E6",
      pointHoverBorderColor: "rgba(187, 38, 73, 1)",
      data: [85, 55, 65, 70, 55, 50, 50]
    }
  ]
};

export const RadarOptions = {
  scale: {
    ticks: {
      min: 0,
      max: 100,
      suggestedMax: 100,
      showLabelBackdrop: false,
      display: false,
      backdropColor: 'rgba(155, 155, 255, 0.75)'
    
    },
    
    angleLines: {
      color: "red",
      lineWidth: 1
    },
    gridLines: {
      color: "rgba(255, 255, 255)",
      circular: true
    }
  }
};
