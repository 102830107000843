import React, { useEffect } from 'react';
import '../../App.css';
import './AboutMe/aboutme.css';
import ArrowDown from './AboutMe/arrowDown';
import AllWrite from './AboutMe/allWrite';
import Circlebar from './AboutMe/circlebar';
import AnimatedPage from './AnimatedPage';
import Aos from "aos";
import 'aos/dist/aos.css'
import './AboutMe/boxes.css'
import $ from 'jquery';
import BarChart from './AboutMe/chart';

/* SVG  */
import { ReactComponent as SVG } from './icon/html_icon.svg';
import { ReactComponent as SVG2 } from './icon/css_icon.svg';
import { ReactComponent as SVG3 } from './icon/javascript_icon.svg';
import { ReactComponent as SVG4 } from './icon/php_icon.svg';
import { ReactComponent as SVG5 } from './icon/react_icon.svg';
import { ReactComponent as SVG6 } from './icon/java_icon.svg';
import { ReactComponent as SVG7 } from './icon/python_icon.svg';
import { ReactComponent as SVG9 } from './icon/tableau_icon.svg';
import { ReactComponent as SVG10 } from './icon/excel_icon.svg';
import { ReactComponent as SVG11 } from './icon/google_icon.svg';
import { ReactComponent as SVG12 } from './icon/pandas_icon.svg';
import { ReactComponent as SVG13 } from './icon/numpy_icon.svg';
import { ReactComponent as SVG14 } from './icon/pytorch_icon.svg';
import { ReactComponent as SVG15 } from './icon/sql_icon.svg';
import { ReactComponent as SVG16 } from './icon/solidity_icon.svg';
import { FaCheckSquare } from "react-icons/fa";

/* IMMAGINI */
import Solidity from './AboutMe/solidity.png';
import Developer from './AboutMe/developer.png';

import { RadarChart } from './AboutMe/radar';

/* FUNZIONE JQUERY CIRCULAR BAR*/

import Donutchart from './AboutMe/Apexchart';


const AboutMe = () => {
    useEffect(() => {
        Aos.init({ duration: 2000 });
    }, [] )
    return (
        <AnimatedPage>
    <div>
    <ArrowDown />
    <h1 className='title_about'>
      <span>&#60;About Me &#47;&#62;</span>
    </h1>
        <section>
            <div className='all_skill'>
                <div className='card_skill web_skill'>
                    <h1 className='title_card'>Web Developer</h1>
                    <li><h3>CSS</h3>
                    <span className='bar'><span className='css_bar'></span></span>
                    </li> 
                    <li><h3>Javascript</h3>
                    <span className='bar'><span className='javascript_bar'></span></span>
                    </li>
                    <li><h3>PHP</h3>
                    <span className='bar'><span className='php_bar'></span></span>
                    </li>
                    <li><h3>Laravel</h3>
                    <span className='bar'><span className='laravel_bar'></span></span>
                    </li>
                    <li><h3>React</h3>
                    <span className='bar'><span className='react_bar'></span></span>
                    </li>
                    <li><h3>Fast API</h3>
                    <span className='bar'><span className='fast_bar'></span></span>
                    </li>
                    <li><h3>DJANGO</h3>
                    <span className='bar'><span className='django_bar'></span></span>
                    </li>         
           
                </div>
                <div className=' card_skill data_science_lenguage'>
                    <h1 className='title_card'>Data Science</h1>
                    <li><h3>Python</h3>
                    <span className='bar'><span className='python_bar'></span></span>
                    </li>
                    <li><h3>R</h3>
                    <span className='bar'><span className='r_bar'></span></span>
                    </li>  
                    <li><h3>SQL</h3>
                    <span className='bar'><span className='sql_bar'></span></span>
                    </li>  
                    <li><h3>Julia</h3>
                    <span className='bar'><span className='julia_bar'></span></span>
                    </li>
                    <li><h3>Dax</h3>
                    <span className='bar'><span className='dax_bar'></span></span>
                    </li>
                    <li><h3>Streamlit</h3>
                    <span className='bar'><span className='streamlit_bar'></span></span>
                    </li> 
                    <li><h3>Dash Plotly</h3>
                    <span className='bar'><span className='dash_bar'></span></span>
                    </li>         
                </div>
                <div className='card_skill python_data_science_libraries'>
                    <h1 className='title_card'>BI Tools and Libraries</h1>
                    <li><h3>Power BI</h3>
                    <span className='bar'><span className='power_bar'></span></span>
                    </li>
                    <li><h3>Pandas</h3>
                    <span className='bar'><span className='pandas_bar'></span></span>
                    </li>  
                    <li><h3>Numpy</h3>
                    <span className='bar'><span className='numpy_bar'></span></span>
                    </li>  
                    <li><h3>pyTorch</h3>
                    <span className='bar'><span className='pytorch_bar'></span></span>
                    </li>  
                    <li><h3>Keras</h3>
                    <span className='bar'><span className='keras_bar'></span></span>
                    </li>
                    <li><h3>SciKit-Learn</h3>
                    <span className='bar'><span className='scikit_learn_bar'></span></span>
                    </li>
                    <li><h3>Altair</h3>
                    <span className='bar'><span className='altair_bar'></span></span>
                    </li>          
                </div>
            </div>
                </section>
                <div className='chart_area'>
                    <div className='donut'>
                        <Donutchart />
                    </div>
                    <div className='radar'>
                        <RadarChart />
                    </div>
                </div>
        <AllWrite />
        <div className='grids'>
            <div data-aos='fade-up' className='boxes box1'>
                    <div className='bar_chart'><BarChart/></div>
                    <ul className='softSkillList'>
                    <li><FaCheckSquare className='checktick' /> Deep Learning and Machine Learning Models </li>
                    <li><FaCheckSquare className='checktick' /> Data Wrangling </li>
                    <li> <FaCheckSquare className='checktick'/> Cluster Analysis and Cloud Model Integration</li>
                    <li> <FaCheckSquare className='checktick'/> Designing Data Engineering Architectures</li>
                    <li> <FaCheckSquare className='checktick' /> Statistics and KPI Creation </li> 
                    <li> <FaCheckSquare className='checktick' /> IoT e Sensor Data Analysis </li>   
                </ul>
            </div>
            <div data-aos='fade-up' className='boxes box2'>
                <ul className='softSkillList'>
                <li> <FaCheckSquare className='checktick'/> Funcional Analysis</li>
                <li> <FaCheckSquare className='checktick' /> Full Stack Developer</li>
                <li> <FaCheckSquare className='checktick' /> Mobile-First Approach</li>    
                <li> <FaCheckSquare className='checktick'/> OOP and Pattern Design</li>
                <li> <FaCheckSquare className='checktick'/> Continuous Learning and Improvement</li>
                <li> <FaCheckSquare className='checktick'/> Cloud and Edge Computing</li>
                </ul>
                <div className='box-image'> <img src={Developer} alt="Developer Full-stack"  className='aboutmeImg'/> </div>                    
            </div>
            <div data-aos='fade-up' className='boxes box3'>                 
                <ul className='softSkillList'>
                <li> <FaCheckSquare className='checktick' /> Smart Contract Development </li>
                <li> <FaCheckSquare className='checktick'/> Beta Development and Deployment using Remix </li>
                <li> <FaCheckSquare className='checktick'/> Truffle and Brownie Frameworks </li>
                </ul>
                 <div className='box-image'> <img src={Solidity} alt="Blockchain Solidity"  className='aboutmeImg'/> </div>      
            </div>
        </div>
    </div>
    </AnimatedPage>
    
    );
}
export default AboutMe;