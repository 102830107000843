import React from 'react';
import './styles.css';

const Header = () => (
  <header className='home-header'>
    <h1>
      <span>&#60;Blog &#47;&#62;</span>
    </h1>
  </header>
);

export default Header;
