import React from 'react';
import '../../App.css';
import SocialIcons from './Home/social';
import Typer from './Home/name';
import { MyComponent } from './Home/vanta';
import AnimatedPage from './AnimatedPage';

const Home = () => {
  return (
    <div>
      <SocialIcons className='icone'/>
      <MyComponent/>
    </div> 
    );
}

export default Home;