import React from 'react';
import './allWrite.css';

const AllWrite = () => {
  return (
    <div className="respon">
      <ul id="techList">
        <li className="python">Python</li>
        <li className="pandas">pandas</li>
        <li className="ia">ia</li>
        <li className="jquery">jquery</li>
        <li className="matplotlib">matplotlib</li>
        <li className="numpy">numpy</li>
        <li className="machinelearning">machine learning</li>
        <li className="tensorflow">tensorflow</li>
        <li className="javascript">javascript</li>
        <li className="responsive">responsive layout</li>
        <li className="ajax">ajax</li>
        <li className="data_vis">data visualization</li>
        <li className="datawarehouse">data warehouse</li>
        <li className="css3">css3</li>
        <li className="skills">skills</li>
        <li className="php">php</li>
        <li className="pnl">pnl</li>
        <li className="server">web server administration</li>
        <li className="project">project managment</li>
        <li className="data">data</li>
        <li className="postgres">postgres</li>
        <li className="deep_learning">deep learning</li>
        <li className="scikit">scikit-learn</li>
        <li className="pytorch">pytorch</li>
        <li className="java">java</li>
        <li className="mysql">m<span>y</span>sql</li>
        <li className="data_science">data science</li>
        <li className="statistic_analysis">statistic analysis</li>
      </ul>
    </div>
  );
}

export default AllWrite;