import React, { useRef, useState } from 'react'
import styled, { keyframes } from 'styled-components'

import music from "./harry_potter_music.mp3"

const Box = styled.div`
display:flex;
cursor:pointer;
z-index:10;
color:#FFF6E6;
margin-right:57vh;
position:absolute;
left:50%;
top:4%;
transform: translate(-50%, -50%);


&>*:nth-child(1){
    animation-delay: 0.2s;
    background-color:#FFF6E6;
}
&>*:nth-child(2){
    animation-delay: 0.3s;
    background-color:#2186C4;
    color:#2186C4;
}
&>*:nth-child(3){
    animation-delay: 0.4s;
    background-color:#FFF6E6;
}
&>*:nth-child(4){
    animation-delay: 0.5s;
    background-color:#2186C4;
    color:#2186C4;
}
&>*:nth-child(5){
    animation-delay: 0.8s;
    background-color:#FFF6E6;
}
&>*:nth-child(6){
    animation-delay: 0.8s;
    background-color:#2186C4;
    color:#2186C4;
}

@media only screen and (max-width: 1150px) {
    top:auto;
    left:24%;
}


`

const play = keyframes`
0%{
    transform:scaleY(1);
}
50%{
    transform:scaleY(2);
}
100%{
    transform:scaleY(1);
}
`
const Line = styled.span`
background: ${props => props.theme.text};
border: 2px solid ${props => props.theme.body};

animation:${play} 0.7s ease infinite;
animation-play-state: ${props => props.click ? "running" : "paused"};
height: 1.3rem;
width: 2px;
margin:0 0.2rem
`

const SoundBar = () => {

    const ref = useRef(null);
    const [click, setClick] = useState(false);

    const handleClick = () => {
        setClick(!click);

        if(!click){
            ref.current.play();
        }else{
            ref.current.pause();
        }
    }
    return (
        <Box className='Box' onClick={() => handleClick()}>
            <Line click={click}/>
            <Line click={click}/>
            <Line click={click}/>
            <Line click={click}/>
            <Line click={click} />
            <Line click={click}/>
            <audio src={music} ref={ref}  loop />
        </Box>
    )
}

export default SoundBar
